import './index.scss';
import $ from 'jquery';
$.fn.webfactoryBlog = function(){
	let $form = $(this).find('form');
	if (!$form.length){
		return;
	}
	$form.on('change', '[name]', function(e){
		let id=$form.attr('id')
		let f = $form.serialize();
		let url = window.location.href.split('?')[0]+'?'+$form.serialize();
		$form.find('[role="items"]').addClass('--is-loading')
		
		$.get(url).then(r=>{
			let $tmp = $('<div>').html(r);
			let html = $tmp.find('#'+id +' [role="items"]').html();
			$form.find('[role="items"]').html(html).removeClass('--is-loading').trigger('webfactory-block-changed');		
			if ($(window).width() < 1024) {
				$('[role="items"] > .row:not(.slick-initialized)').each(function(){	
					$(this).webfactoryCarouselBlogItems();
				});
			}
		})
	});
}

$.fn.webfactoryCarouselBlog = function(){
	let prev = '<span class="slick-control slick-control-prev wf-icon-arrow-left wf-icon-small d-flex align-items-center justify-content-center mb-5" ></span>'
	let next = '<span class="slick-control slick-control-next wf-icon-arrow-right wf-icon-small d-flex align-items-center justify-content-center mb-5" ></span>'
	$(this).slick({    
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		prevArrow: prev,
		nextArrow: next,
  	});
}

$.fn.webfactoryCarouselBlogItems = function(){	
	$(this).removeClass('row-cols-md-3');		
	$(this).slick({    
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		arrows: false,
		centerMode: true,
		centerPadding: '0',
		draggable: false,
		swipe: true
  	});
}


	

$(document).ready(function(){
	$('.wp-block-webfactory-blog').each(function(){
		$(this).webfactoryBlog();
	})
	$(window).on('load resize', function(){
    if ($(window).width() < 768) {
		$('.wp-block-webfactory-blog-filter-tabs:not(.slick-initialized)').each(function(){		
			$(this).webfactoryCarouselBlog();
		});
	}else if($('.wp-block-webfactory-blog-filter-tabs').hasClass('slick-initialized')){
		$('.wp-block-webfactory-blog-filter-tabs').slick('unslick');
	}

	if ($(window).width() < 1024) {
		$('[role="items"] > .row:not(.slick-initialized)').each(function(){	
			$(this).webfactoryCarouselBlogItems();
		});
	}else if($('[role="items"] > .row').hasClass('slick-initialized')){
		$('[role="items"] > .row').slick('unslick').toggleClass('row-cols-md-3');
	}
	});
})

setInterval(function(){
	$('.has-animation').removeClass('has-animation')
},1000)